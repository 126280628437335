<template>
<body>
    <!-- ::::::  Start Header Section  ::::::  -->
  <Header/>
  <!-- :::::: End Header Section ::::::  -->  
    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav"> <router-link to="/">Home</router-link></li>
                        <li class="page-breadcrumb__nav active">Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->
    <!-- :::::: Start Main Container Wrapper :::::: -->
    <main id="main-container" class="main-container inner-page-container">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-5">
                    <div class="contact-info-wrap gray-bg m-t-40">
                        <div class="single-contact-info">
                            <div class="contact-icon">
                                <i class="fas fa-phone-alt"></i>
                            </div>
                            <div class="contact-info-dec">
                                <a :href="'tel:'+LinksDetails.mobile">{{LinksDetails.mobile}}</a>
                            </div>
                        </div>
                        <div class="single-contact-info">
                            <div class="contact-icon">
                                <i class="fas fa-globe-asia"></i>
                            </div>
                            <div class="contact-info-dec">
                                <a :href="'mailto:'+LinksDetails.email">{{LinksDetails.email}}</a>
                            </div>
                        </div>
                        <div class="single-contact-info">
                            <div class="contact-icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <div class="contact-info-dec">
                                <span v-html="LinksDetails.address"></span>
                            </div>
                        </div>
                        <div class="contact-social m-t-40">
                            <h5>Follow Us</h5>
                            <div class="social-link">
                                <ul>
                                    <li v-if="LinksDetails.facebook_link"><a :href="LinksDetails.facebook_link"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li  v-if="LinksDetails.twitter_link"><a :href="LinksDetails.twitter_link"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li v-if="LinksDetails.google_link"><a :href="LinksDetails.google_link"><i class="fab fa-google-plus-g"></i></a>
                                    </li>
                                    <li v-if="LinksDetails.instagram_link"><a :href="LinksDetails.instagram_link" ><i class="fab fa-instagram"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-7">
                    <div class="contact-form gray-bg m-t-40">
                        <div class="section-content">
                            <h5 class="section-content__title">Get In Touch</h5>
                        </div>
                        <ValidationObserver ref="form">
                        <!-- <form class="contact-form-style" id="contact-form" action="#" method="POST"> -->
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-box__single-group">
                                         <validation-provider rules="required|alpha_spaces|min:3" v-slot="{ errors }">
                                                <input type="text" v-model="name" name="name" id="name" placeholder="Name*">
                                                <span class="error-msg">{{ errors[0] }}</span>
                                            </validation-provider>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                   <div class="form-box__single-group">
                                         <validation-provider rules="required|email" v-slot="{ errors }">
                                                <input type="email" v-model="email" class="form-control" name="email" id="email" placeholder="Email*">
                                                <span class="error-msg">{{ errors[0] }}</span>
                                            </validation-provider>
                                    </div>
                                </div>

  <div class="col-lg-6">
                                    <div class="form-box__single-group">
                                         <validation-provider rules="required|integer|min:10|max:10" v-slot="{ errors }">
                                                <input type="number" class="form-control" v-model="mobile" name="phone" id="phone" placeholder="Phone*">
                                                <span class="error-msg">{{ errors[0] }}</span>
                                            </validation-provider>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                   <div class="form-box__single-group">
                                        <validation-provider rules="required|alpha_spaces|min:5" v-slot="{ errors }">
                                                <input type="text" class="form-control" v-model="subject" name="subject" id="subject" placeholder="Subject*">
                                                <span class="error-msg">{{ errors[0] }}</span>
                                            </validation-provider>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-box__single-group">
                                       <validation-provider rules="required|alpha_spaces|min:5" v-slot="{ errors }">
                                                <textarea name="note" v-model="msg" id="note" placeholder="Case description*"></textarea>
                                                <span class="error-msg comment-error">{{ errors[0] }}</span>
                                            </validation-provider>
                                    </div>
                                    <button  @click="switchLoc" class="btn btn--box btn--medium btn--radius-tiny btn--black btn--black-hover-green btn--uppercase font--bold m-t-30" type="submit">Send</button>
                                </div>
                            </div>
                        <!-- </form> -->
                         </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
    </main> <!-- :::::: End MainContainer Wrapper :::::: -->

    <!-- ::::::  Start  Footer ::::::  -->
   <Footer/>
   <!-- ::::::  End  Footer ::::::  -->
</body>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import Header from "@/components/navbar";
import Footer from "@/components/footer";
import VueRecaptcha from 'vue-recaptcha';
import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate";

export default {
    components: {
        Header,
        Footer,
        VueRecaptcha,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            name: '',
            mobile: '',
            email: '',
            subject: '',
            msg: '',
            reCap: false,
            LinksDetails: []
        };
    },
    mounted() {
   this.goToTop ()

        this.site_setting()
        this.uid = localStorage.getItem("user");
    },

    methods: {
        markRecaptchaAsVerified(response) {
        this.pleaseTickRecaptchaMessage = 'This field is required';
        this.recaptchaVerified = true;
        },
        switchLoc() {
            var vali = this.$refs.form.validate();
                // if (!this.recaptchaVerified) {
                // this.pleaseTickRecaptchaMessage = 'Please tick recaptcha.';
                // this.reCap = true
              
                // }else{
                this.reCap = false
                      vali.then(val => {
                    if (val == true) {
                        this.contact_submit()
                    }
                }

            )
                // }
          
        },

        quickbox() {
            if ($(window).width() > 767) {
                $(".quickview-button").magnificPopup({
                    type: "iframe",
                    delegate: "a",
                    preloader: true,
                    tLoading: "Loading image #%curr%...",
                });
            }
        },
        contact_submit() {
            var vi = this;
            if (this.name == "") {
                this.$toasted.global.error({
                    message: "Please enter name"
                });
            } else if (this.email == "") {
                this.$toasted.global.error({
                    message: "Please enter email"
                });
            } else if (this.mobile == "") {
                this.$toasted.global.error({
                    message: "Please enter mobile number"
                });
            } else if (this.subject == "") {
                this.$toasted.global.error({
                    message: "Please enter subject"
                });
            } else if (this.msg == "") {
                this.$toasted.global.error({
                    message: "Please enter Message"
                });
            } else {
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "contact",
                        name: this.name,
                        email: this.email,
                        mobile: this.mobile,
                        subject: this.subject,
                        msg: this.msg,
                    },
                }).then(function (response) {
                    var data = response.data;
                    console.log(response.data)
                    if (data.status == "success") {
                        vi.$toasted.global.success({
                            message: data.msg
                        });

                        vi.name = "";
                        vi.email = "";
                        vi.subject = "";
                        vi.mobile = "";
                        vi.msg = "";
                        $('.error-msg').addClass('hide');
                        setTimeout(function () {
                            $('.error-msg').removeClass('hide');
                        }, 10000);

                    } else {
                        vi.$toasted.global.error({
                            message: data.msg
                        });
                    }
                });
            }
        },
    },
};
</script>
